<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>
      <alter-form labelWidth="140px" ref="alterForm" @formSubmit="formSubmit" :modifyData="modifyData" :formData="formData" :collapseLength="collapseLength"></alter-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
