// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { putOffStoreFormData } from '@/services/store.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import Utils from '@/utils/Utils.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      activeName: 'second'
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    },
    init () {
      // this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      // getShopSelectList().then(res => {
      //   if (res.status === 200) {
      //     this.formData[0].createDateItem[0].options = res.data.shops
      //   }
      // })
      // getPoiableTypeList().then(res => {
      //   if (res.status === 200) {
      //     this.formData[1].createDateItem[0].options = res.data.poiable_types
      //   }
      // })
      // getOffStoreEditDetails(this.$route.params.id).then(res => {
      //   if (res.status === 200) {
      //     this.modifyData = res.data.store
      //     if (Utils.isNull(this.modifyData.operation_hours)) {
      //       this.modifyData.operation_hours = ['', '']
      //     } else {
      //       this.modifyData.operation_hours = this.modifyData.operation_hours.split('-')
      //     }
      //   }
      // }).finally(() => {
      this.$store.state.app.loading.close()
      // })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        store: obj
      }
      Object.keys(params.store).forEach(res => {
        if (typeof params.store[res] === 'boolean') {
          if (params.store[res] === true) params.store[res] = '1'
          if (params.store[res] === false) params.store[res] = '0'
        }
      })
      if (params.store.operation_hours instanceof Array || !Utils.isNull(params.store.operation_hours)) {
        if (params.store.operation_hours[0] === '') {
          params.store.operation_hours = ''
        } else {
          params.store.operation_hours = params.store.operation_hours.join('-')
        }
      }
      putOffStoreFormData(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'storesManagerList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
